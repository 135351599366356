@import "variables";
@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/typography";
@import "mixins/button";

.hero-carousel {
  &.ribbon--active {
    .hero-carousel__slide {
      @include media-breakpoint-up(md) {
        height: calc(100vh - 244px);
      }
    }
  }
  &:not(.ribbon--active) {
    .hero-carousel__slide {
      @include media-breakpoint-up(md) {
        height: calc(100vh - 186px);
      }
    }
  }
}
.hero-carousel__inner {
  --transparent-background-color: rgba(0, 0, 0, 0.6);

  .glide__bullets {
    background: var(--transparent-background-color);
    border-radius: 25px;
    padding: 6px 2px;

    @include media-breakpoint-up(sm) {
      padding: 12px 4px;
    }
  }

  .glide__bullet {
    background-color: transparent;
    border: 4px solid white;
    width: 10px;
    height: 10px;
    margin: 0 0.25em;

    &:focus {
      background-color: white;
      border-color: $purple-400;
    }

    &.glide__bullet--active {
      background-color: white;
      outline: 2px white solid;
      outline-offset: 2px;

      &:focus {
        outline-color: $purple-400;
        border-color: white;
      }
    }

    @include media-breakpoint-up(sm) {
      margin: 0 0.5em;
      &.glide__bullet--active {
        outline-width: 4px;
        outline-offset: 4px;
      }
    }
  }

  .glide__arrows {
    display: none;
    @include media-breakpoint-up(md) {
      display: revert;
    }
  }

  .glide__arrow {
    background-color: var(--transparent-background-color);
    padding: 12px;
    max-height: 90%;
    border-radius: 50px;
    transition: height 200ms ease;
    will-change: height;

    border: none;
    box-shadow: none;
    text-shadow: none;

    height: 48px;
    @include media-breakpoint-up(lg) {
      height: 73px;
    }

    &:hover {
      height: 150px;
      @include media-breakpoint-up(lg) {
        height: 300px;
      }
    }

    &:focus {
      outline: 3px solid $purple-500;
    }

    &:active {
      color: $gray-400;
    }

    &.glide__arrow--left svg {
      left: -2px;
    }
    &.glide__arrow--right svg {
      right: -2px;
    }

    svg {
      position: relative;
      width: 24px;
      height: 24px;

      @include media-breakpoint-up(lg) {
        width: 48px;
        height: 48px;
      }
    }
  }

  @media (prefers-reduced-motion) {
    .glide__arrow {
      height: auto;
      transition: none;

      &:hover {
        height: auto;
      }
    }
  }
}

.hero-carousel__slide {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  color: $white;

  flex-direction: column;
  padding: 0;
  @include media-breakpoint-up(md) {
    flex-direction: revert;
    padding: 58px 105px;
  }
  @include media-breakpoint-up(lg) {
    padding: 116px 210px;
  }

  &[data-align^="top-"] {
    align-items: center;
    @include media-breakpoint-up(md) {
      align-items: flex-start;
    }
  }
  &[data-align^="middle-"] {
    align-items: center;
  }
  &[data-align^="bottom-"] {
    align-items: center;
    @include media-breakpoint-up(md) {
      align-items: flex-end;
    }
  }

  justify-content: center;
  &[data-align$="-left"] {
    justify-content: center;
    text-align: center;
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      text-align: left;
    }
  }
  &[data-align$="-center"] {
    justify-content: center;
    text-align: center;
  }
  &[data-align$="-right"] {
    justify-content: center;
    text-align: center;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
      text-align: right;
    }
  }
}

.hero-carousel__slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 350px;
  padding: 25px 20px 20px 20px;
  z-index: 3;

  @include media-breakpoint-up(md) {
    display: revert;
    max-width: 50vw;
  }
}

.hero-carousel__spacer {
  display: block;
  margin: 0;
  padding: 0;
  border: none;

  height: calc(var(--size) * 0.5);
  @include media-breakpoint-up(lg) {
    height: var(--size);
  }
}

.hero-carousel__slide-heading {
  margin: 0;
}

.hero-carousel__slide-body {
  margin: 0;

  font-size: 2rem;
  @include media-breakpoint-up(md) {
    font-size: 2.4rem;
    max-width: 588px;
  }
}

.hero-carousel__slide-link {
  min-width: 282px;

  svg {
    margin-right: 0;
    margin-left: 10px;
  }
}

.hero-carousel__slide-overlay {
  display: none;

  @include media-breakpoint-up(md) {
    display: revert;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 1;
    z-index: 2;
  }
}

.hero-carousel__slide-img--desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: revert;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
  }

  &[data-align^="top-"] {
    background-position-y: top;
  }
  &[data-align^="middle-"] {
    background-position-y: center;
  }
  &[data-align^="bottom-"] {
    background-position-y: bottom;
  }

  &[data-align$="-left"] {
    background-position-x: left;
  }
  &[data-align$="-center"] {
    background-position-x: center;
  }
  &[data-align$="-right"] {
    background-position-x: right;
  }
}

.hero-carousel__slide-img--mobile {
  width: 100%;
  margin-top: auto;
  flex-grow: 1;

  img {
    display: block;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

// Themes
@mixin hero-carousel-theme($color, $button-color, $background) {
  background: $background;
  color: $color;

  &[data-align^="top-left"] {
    .hero-carousel__slide-overlay {
      @include media-breakpoint-up(md) {
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="top-right"] {
    .hero-carousel__slide-overlay {
      @include media-breakpoint-up(md) {
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="bottom-left"] {
    .hero-carousel__slide-overlay {
      @include media-breakpoint-up(md) {
        background-image: linear-gradient(
          to left,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  &[data-align^="bottom-right"] {
    .hero-carousel__slide-overlay {
      @include media-breakpoint-up(md) {
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0) 50%,
          rgba($background, 0.5) 100%
        );
      }
    }
  }

  .hero-carousel__slide-heading,
  .hero-carousel__slide-body {
    @include media-breakpoint-up(md) {
      text-shadow: 0px 0px 8px rgba($background, 0.1);
    }
  }

  .button {
    @if $button-color == white {
      @include button-white();
    } @else if $button-color == orange {
      @include button-orange();
    } @else {
      // default
    }

    &.button--orange {
      @include button-orange();
    }

    &.button--black {
      @include button-black();
    }

    &.button--white {
      @include button-white();
    }
  }
}

.hero-carousel--theme-dark {
  @include hero-carousel-theme($white, white, $black);
}
.hero-carousel--theme-light {
  @include hero-carousel-theme($gray-900, black, $gray-200);
}
